button.sresizer {
  position:absolute;
  top:0;
  right:-2px;
  height:100%;
  width:4px;
  background-color:#d3d3d3;
  cursor: col-resize;
  border:none;
  outline:none;
}

button.sresizer:hover {
  background-color:var(--primary);
}

table.sgrid {
  border-collapse: separate;
  border-spacing: 0;
}

table.sgrid thead {
  position: sticky;
  top:6rem;
  z-index:2;
}

header.extra-info + header.header + div.container:not(.logon) table.sgrid thead {
  top:7.5rem;
}

table.sgrid > tbody table.sgrid > thead {
  z-index:1;
}

table.sgrid > tbody {
  background-color: white;
}

table.sgrid > * > tr:not(.filter) > th {
  border:1px solid #dedede;
  border-left-width: 0;
  border-right-width: 0;  
}

table.sgrid > tbody > tr:not(:last-child) > td {
  border-bottom:1px solid #dedede;
}

table.sgrid.stable > * > * > th:not(.filter) {
  position:relative;
}

/* sort */
table.sgrid.stable > * > * > th span.sort {
  display:inline-block;
  position:relative;
  height:15px;
}

table.sgrid.stable > thead > th.sort:hover {
  cursor:pointer;
  background-color:#eaeef2;
}

table.sgrid.stable > * > * > th span.sort:after{
  content:"\0020"
}

table.sgrid.stable > * > tr:not(.filter) th svg.sort {
  position:absolute;
  top:0;
  display:none;
}

table.sgrid.stable > * > tr:not(.filter) th span.sort-asc svg.sort:first-child {
  display:inline-block;
}

table.sgrid.stable  > * > tr:not(.filter) th span.sort-desc svg.sort:last-child {
  display:inline-block;
}

/* group */
table.sgrid.stable > * > * > th._group > div.is_group {
  position:relative;
  display:inline-block;
  transition: all ease-in-out 0.25s;
}

table.sgrid.stable > * > * > th._group > div.is_group:not(.show) {
  display:none;
  visibility: hidden;
  /* margin-left:-20px; */
  opacity:0;
}

table.sgrid.stable > * > * > th._group:hover > div.is_group {
  visibility: visible;
  display:inline-block;
  margin-left:0;
  width:auto;
  opacity:1;
}

/* filter */
table.sgrid.stable > * > * > th span.filter {
  display:inline-block;
  position:relative;
  height:15px;
}

table.sgrid.stable > thead > th.filter:hover {
  cursor:pointer;
  background-color:#eaeef2;
}

table.sgrid.stable > * > * > th span.filter:after{
  content:"\0020"
}

table.sgrid.stable > * > tr:not(.filter) th svg.filter {
  position:absolute;
  top:0;
  display:none;
}

table.sgrid.stable > * > tr:not(.filter) th span.filter svg {
  display:inline-block;
}

/* grid-filter */
table.sgrid.stable > * > tr.filter {
  background-color:#f5f5f5;
}

table.sgrid.stable > * > tr.filter th {
  padding:0;
}

table.sgrid.stable > thead > tr + tr th input {
  width:100%;
}

table.sgrid.stable > tbody > tr > td:focus {
  background: var(--tertiary);
  outline:none;
}

table.sgrid.stable  > * > tr:hover:not(.expandable) {
  background-color:#f8f8f8;
}

table.sgrid > * > tr:not(.filter) th {
  border:1px solid #dedede;
  border-left-width: 0;
  border-right-width: 0;
}

table.sgrid > * > tr.filter th {
  border:none;
  border-bottom:1px solid #dedede;
}

table.sgrid > tbody > tr:not(:last-child) > td,
table.sgrid > tfoot > tr > td  {
  border-bottom:1px solid #dedede;
}

table.sgrid.stable > tbody > tr:last-child > td { 
  border-bottom:1px solid #dedede;
}

button.sresizer {
  position:absolute;
  top:0;
  right:-2px;
  height:100%;
  width:4px;
  background-color:#d3d3d3;
  cursor: col-resize;
  border:none;
  outline:none;
}

button.sresizer:hover {
  background-color:var(--primary);
}

table.sgrid.stable > * > tr.expandable table.sgrid.stable > tbody {
  background-color: #F9FAFB;
}

table.sgrid.stable > tbody > tr.status-quantity {
  background: repeating-linear-gradient(45deg, rgba(220,0,0,0.1), rgba(220,0,0,0.1) 10px, transparent 10px, transparent 20px);
}

table.sgrid.stable > tbody > tr.status-price {
  background: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0,0,220,0.1) 10px, rgba(0,0,220,0.1) 20px);
}

table.sgrid.stable > tbody > tr.status-quantity.status-price {
  background: repeating-linear-gradient(45deg, rgba(220,0,0,0.1), rgba(220,0,0,0.1) 10px, rgba(0,0,220,0.1) 10px, rgba(0,0,220,0.1) 20px);
}

table.sgrid > tbody > tr:has(button[data-name="cart-button"].text-primary) {
  background-color: #00c44810;
}

@media only screen and (max-width: 767px) {
  table.sgrid.stable {
    border-collapse: collapse; 
  }

  table.sgrid.stable > * > * > th, table.sgrid.stable > * > * > td {
    display: block;
    border: 0;
    width: 100%;
  }

  table.sgrid.stable > * > * > th:not(.is-mobile), table.sgrid.stable > * > * > td.hidden {
    display:none;
  }

  table.sgrid.stable > tbody > tr > td:not(.name-hidden):not([data-name=""]) {
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row-reverse; */
    justify-content: space-between;
    align-items: center;
  }

  table.sgrid.stable > tbody > tr > td:not(.name-hidden):not(.shadow-detail):before {
    display:inline-block;
    content: attr(data-name);
    font-weight:600;
    color: #999999;
    font-size:0.9em;
    padding-left: 0.5rem;
  }

  table.sgrid.stable > tbody > tr > td:not(.name-hidden):not(.shadow-detail):empty {
    display:none;
    visibility: hidden;
  }

  /* table.sgrid.stable tbody tr td:not(.name-hidden) > * {
    float:right;
  } */
  /* table.sgrid.stable tbody tr td:not(.name-hidden) > div {
    float:inherit;
  } */

  /* table.sgrid.stable tbody tr:not(.expandable) {
    border-bottom:3px dashed #dedede;
  } 
  */

  table.sgrid.stable > tbody > tr.expandable,
  table.sgrid.stable > tbody > tr.expandable table.sgrid.stable > thead > tr
  /* table.sgrid.stable tbody tr.expandable table.sgrid.stable tbody tr  */
  {
    border-bottom:none;
  }

  table.sgrid.stable > tbody {
    background: transparent;
    /* overflow:hidden; */

  }

  /* DASHED */
  table.sgrid > tbody > tr:not(:last-child) > td:last-child,
  table.sgrid > tbody > tr.expandable > td:last-child {
    border-bottom:none;
  }

  table.sgrid.stable > tbody > tr {
    border-left:2px dashed #dedede;
    border-right:2px dashed #dedede;
  }

  table.sgrid.stable > tbody > tr:first-child {
    border-top:2px dashed #dedede;
  }

  table.sgrid.stable > tbody:after {
    display:block;
    content:"\200C";
    line-height: 0;
    width:100%;
    border-bottom:2px dashed #dedede;
  }

  table.sgrid.stable > tbody table.sgrid.stable > tbody > tr {
    border-left:none;
    border-right:none;
  }

  table.sgrid.stable > tbody table.sgrid.stable > tbody > tr:first-child {
    border-top:3px solid #dedede;
  }

  table.sgrid.stable > tbody table.sgrid.stable > tbody:after {
    border-bottom:none;
  }

  table.sgrid.stable > tbody > tr > td {
    background-color: white;
  }

  table.sgrid.stable > tbody table.sgrid.stable > tbody > tr > td {
    background-color: #fafafa;
  }

  table.sgrid.stable > thead + tbody ~ tbody:before {
    display:block;
    content:"\200C";
    line-height:2em;
    /* visibility: hidden; */
    background: transparent;
  }

  table.sgrid.stable > tbody:before:last-child {
    display:none;
  }
}

/* group table */
table.sgrid.stable > * > tr.group-row:not(.group-row-show) {
  display:none;
}

/* table.sgrid.stable tbody tr.group-row-show:nth-child(2n+1)  {
  background-color:#fafafa;
}

table.sgrid.stable tbody + tbody:nth-child(2n) > tr.group-row {
  background-color:#fafafa;
} */

/* draggable */
table.sgrid.stable > * > tr > th.drag {
  cursor: move;
  user-select: none;
}
.clone-list {
  border: 1px solid #ccc;
  display: block;
  background: #EAEEF2;
  opacity: 0.5;
}
.clone-table {
  border-collapse: collapse;
  border: none;
}
.clone-table th, .clone-table td {
  border: 1px solid #ccc;
  border-left: none;
  border-top: none;
  padding: 0.5rem;
}
.dragging {
  z-index: 999;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton-box {
  display: inline-block;
  height: 40px;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
  margin:12px;
  box-sizing: border-box;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}

/* .skeleton-grid > div {
  border-bottom: 1px solid #dedede;
} */
